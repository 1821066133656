import React, { ComponentProps } from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export type NavDropdownPanelProps = ComponentProps<typeof Grid> & {
  show?: boolean
}

export const NavDropdownPanel: React.FC<NavDropdownPanelProps> = ({ show=false, children, ...props }) => {
  return (
    <StyledDropdownPanel container $show={show} direction="column" justifyContent="flex-start" alignItems="flex-start" {...props}>
      { children }
      <StyledDropdownPolygon />
    </StyledDropdownPanel>
  )
}

export const StyledDropdownPanel = styled(Grid)<{$show?: boolean}>`
  display: ${props => props.$show ? 'block' : 'none'};
  position: absolute;
  cursor: default;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  background: #f8f8f8;

  ${props => props.theme.breakpoints.up('sm')} {
    padding: ${props => props.theme.typography.pxToRem(45)} ${props => props.theme.typography.pxToRem(50)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding: ${props => props.theme.typography.pxToRem(45)} ${props => props.theme.typography.pxToRem(100)};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    padding: ${props => props.theme.typography.pxToRem(90)} ${props => props.theme.typography.pxToRem(250)};
  }

`

export const StyledDropdownPolygon = styled.div`
  position: relative;
  margin-bottom: -${props => props.theme.typography.pxToRem(90)};
  margin-left: -100%;
  background: white;
  width: 200vw;
  height: ${props => props.theme.typography.pxToRem(90)};
  clip-path: polygon(0 120%, 100% 0%, 100% 100%, 0% 100%);
`