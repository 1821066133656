import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { MAX_NAVITEM_PER_LEVEL, MAX_NUM_SUBLEVEL, MobileMenuContextType, MobileMenuContext } from './MobileMenuContext'
import { isBrowser } from '@atomixdesign/rem-scaling'

export type MobileMenuWrapperProps = React.ComponentProps<typeof StyledWraperContainer> & {
  isMenuOpen?: boolean
  onCloseMenu?: () => void
  portal?: Element | string | null
  children: React.ReactNode
}

export const StyledWraperContainer= styled.div`
  position: absolute;
  width: auto;
`

export const MobileMenuWrapper: React.FC<MobileMenuWrapperProps> = ({ children, portal, isMenuOpen, onCloseMenu, ...props }) => {
  const [menuState, setMenuState] = useState<boolean[]>(Array.from<boolean>({ length: 1 + MAX_NUM_SUBLEVEL * MAX_NAVITEM_PER_LEVEL }).fill(false))

  //get portal
  if (typeof portal === 'string' && isBrowser()) {
    portal = document.querySelector(portal)
  }
 
  const mobileMenuContext: MobileMenuContextType = useMemo(() => ({
    portal: portal as Element,
    menuState: menuState,
    setMenuState: setMenuState,
    getItemIndex: (level, idx) => {
      return (MAX_NAVITEM_PER_LEVEL * Math.max(0, (level - 1)) + idx)
    },
    setMenuOpen: (level, idx) => {
      const newMenuState = [...menuState]

      newMenuState[MAX_NAVITEM_PER_LEVEL * level + idx ] = true
      setMenuState(newMenuState)
    },
    setMenuClose: (level, idx) => {
      const newMenuState = [...menuState]

      newMenuState[MAX_NAVITEM_PER_LEVEL * Math.max(0, (level - 1)) + idx] = false
      setMenuState(newMenuState)
    },
  }), [menuState, portal])

  useEffect(() => {
    if (isMenuOpen) {
      mobileMenuContext.setMenuOpen(0, 0)
    }
    else {
      setMenuState(Array.from<boolean>({ length: 1 + MAX_NUM_SUBLEVEL * MAX_NAVITEM_PER_LEVEL }).fill(false))
    }
  }, [isMenuOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledWraperContainer {...props}>
      <MobileMenuContext.Provider value={mobileMenuContext}>
        { children }
      </MobileMenuContext.Provider>
    </StyledWraperContainer>
  )
}
