import React, { createContext } from 'react'

export const MAX_NUM_SUBLEVEL = 2
export const MAX_NAVITEM_PER_LEVEL = 10

export type MobileMenuContextType = {
  portal: Element | null
  menuState: boolean[]
  setMenuState: React.Dispatch<React.SetStateAction<boolean[]>>
  getItemIndex: (level: number, idx: number) => number
  setMenuOpen: (level: number, idx: number) => void | null
  setMenuClose: (level: number, idx: number) => void | null
}

export const MobileMenuContext = createContext<MobileMenuContextType>({
  portal: null,
  menuState: [],
  setMenuState: () => {},
  getItemIndex: (level=0, idx=0) => {return (MAX_NAVITEM_PER_LEVEL * Math.max(0, (level - 1)) + idx)},
  setMenuOpen: null,
  setMenuClose: null,
})
