import styled from 'styled-components'
import { Button, Grid } from '@material-ui/core'
import { Icon } from '../../icon/icon'

const getListItemBackgroundColor = (isTitle, isHeader, isSecondary, isBack) => {
  if (isTitle)
    return 'white'
  if (isHeader || isSecondary)
    return '#F8F8F8'
  if (isBack)
    return '#FCF8EF'

  return 'white'
}

export const StyledListItem = styled.li<{$header?: boolean; $isTitle?: boolean; $utility?: boolean; $back?: boolean; $secondary?: boolean}>`
  height: ${props => props.$utility ? props.theme.typography.pxToRem(52) : props.theme.typography.pxToRem(66)};
  width: auto;
  border-bottom: ${props => props.$utility ? '0': '1px solid rgba(0, 0, 0, 0.25)'};
  background-color: ${props => getListItemBackgroundColor(props.$isTitle, props.$header, props.$secondary, props.$back)};
  cursor: ${props => !props.$header && 'pointer'};
`

export const StyledListItemGrid = styled(Grid)`
  height: 100%;
  padding: 0 ${props => props.theme.typography.pxToRem(22)} 0 ${props => props.theme.typography.pxToRem(40)};
`

export const StyledHeaderText = styled.p<{ $secondary?: boolean }>`
  color: ${props => props.$secondary ? props.theme.palette.text.secondary : props.theme.palette.secondary.dark};
  margin: 0 ${props => props.$secondary ? 0 : props.theme.typography.pxToRem(4)} 0 0;
  font-weight: 500;
`

export const StyledCallButton = styled(Button)`
  border: 2px solid ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(15)};
  margin-right: ${props => props.theme.typography.pxToRem(20)};
`

export const StyledTextSpan = styled.span`
  display: flex;
  font-size: ${props => props.theme.typography.pxToRem(16)};
`

export const StyledButton = styled(Button)`
  min-width: unset;
`

export const StyledCloseIcon = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(34)};
`

export const StyledMenuItemText = styled.p`
  margin: 0;
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(18)};
  font-weight: 500;
`

export const StyledLinkItemText = styled.a<{$isTitle?:boolean; $isUtility?:boolean}>`
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.$isTitle ? props.theme.typography.pxToRem(22) : props.theme.typography.pxToRem(18)};
  margin-bottom: 0;
  transition: color 0.2s ease;
  text-decoration: none;
  font-weight: ${$props => !$props.$isUtility && '500'};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(19)};
  }

  &:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }
`
