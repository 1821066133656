import React from 'react'
import { Grid } from '@material-ui/core'
import Link, { LinkProps } from 'next/link'
import { faChevronCircleRight } from '@fortawesome/pro-light-svg-icons'
import { StyledOverviewContainer, StyledOverviewTitle, StyledOverviewCheveron } from './styledNavDropdownUtilityBar'

export type UtilityBarWrapperProps = Omit<React.ComponentProps<typeof Grid>, 'direction' | 'justifyContent' | 'alignItems'> & Pick<LinkProps, 'href'> & {
  title: string
  showOverview?: boolean
}

export const UtilityBarWrapper: React.FC<UtilityBarWrapperProps> = ({ title, href, children, showOverview=true, ...props }) => {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" {...props}>
      <Grid item>
        <Grid container direction="row" justifyContent="flex-start">
          {children}
        </Grid>
      </Grid>
      {showOverview && (
        <Grid item>
          <StyledOverviewContainer>
            <Link href={href} passHref>
              <StyledOverviewTitle>
                { title } Overview Page
              </StyledOverviewTitle>
            </Link>
            <StyledOverviewCheveron icon={faChevronCircleRight} />
          </StyledOverviewContainer>
        </Grid>
      )}
    </Grid>
  )
}
