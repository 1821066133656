import { LayoutQueryFunction } from '../layout'
import { getClient } from '../../apollo/client'
import { gql } from '@apollo/client'
import { FooterQuery } from './__generated__/footer'

export type FooterGlobals = FooterQuery

export const footerGlobalsDefault: FooterGlobals = null // eslint-disable-line

export const FOOTER_QUERY = gql`
  fragment FooterNode on footerNavigation_Node {
    title
    url
    data
    type
    children {
      title
      url
      data
      type
    }
  }
  
  fragment FooterSublink on footerSubLinks_BlockType {
    footerSubLinktext
    footerSubLinkUrl
  }
  
  query FooterQuery {
    globalSet(handle: "footer") {
      ... on footer_GlobalSet {
        footerQuickLinks {
          ... on footerQuickLinks_Footer_QuickLink_Button_BlockType {
            footer_quick_link_title
            footer_quick_link_subtext
            footer_quickLink_Url
            footer_quickLink_icon {
              url
            }
          }
        }
        footerSubLinks {
          ...FooterSublink
        }
        simpleText
      }
    }
    nodes(navHandle: "footerNavigation", level: 1) {
      ...FooterNode
    }
  }
`

export const getFooterGlobals: LayoutQueryFunction<null> = async () => {
  const { data } = await getClient().query({
    query: FOOTER_QUERY,
  })

  return data
}