import React, { useState, useEffect, useCallback, createContext, SetStateAction, Dispatch } from 'react'

import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'
import { SearchBar } from '../searchBar/SearchBar'
import { StyledBrokenBarIcon, StyledIconNavItem, StyledNavBSB, StyledLoginButton } from './StyledNavComponents'
import { StyledNavigationContainer, StyledMenuContainer, StyledRightMenuContainer } from './StyledNavContainers'
import { StyledCreditUnionLogo as CreditUnionLogo } from '../logo/logo'
import { faLock } from '@fortawesome/pro-regular-svg-icons'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Icon } from '../icon/icon'
import { BrokenBarIcon } from '../icon/CreditUnionIcon'

export type PrimaryNavigationProps = {
  secondary?: boolean
  BSB: string
  openMobileMenu?: () => void
}

export type HideOnScrollType = {
  window?: () => Window
  children: React.ReactElement
}

type SearchBarContextType = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const SearchBarContext = createContext<SearchBarContextType>({
  isOpen: false,
  setIsOpen: () => {},
})

export const PrimaryNavigation: React.FC<PrimaryNavigationProps> = ({ BSB, secondary = false, openMobileMenu, ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  //Logo Width, Height
  const [logoWidth, setLogoWidth] = useState(isMobile ? 100 : (secondary ? 117 : 146))
  const [logoHeight, setLogoHeight] = useState(isMobile ? 48 : (secondary ? 56 : 70))

  //Fix a problem with media query initialize with false always
  useEffect(() => {
    setLogoWidth(isMobile ? 100 : (secondary ? 117 : 146))
    setLogoHeight(isMobile ? 100 : (secondary ? 117 : 146))
  }, [isMobile, secondary])

  // Navbar State
  const [isNavSticky, setIsNavSticky] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const [visibleHeader, setVisibleHeader] = useState(true)



  const handleNavigation = useCallback((e) => {
    const window = e.currentTarget

    if (window) {
      if (!isNavSticky && window.scrollY >= 80) {
        setIsNavSticky(true)
        setLogoHeight(117)
        setLogoWidth(41)
      }
      else if(isNavSticky && window.scrollY >=140) {
        setVisibleHeader(false);
      }
      else if (isNavSticky && window.scrollY < 80) {
        setIsNavSticky(false)
        setVisibleHeader(true);
        setLogoWidth(isMobile ? 100 : (secondary ? 117 : 146))
        setLogoHeight(isMobile ? 48 : (secondary ? 56 : 70))
      }
    }
  }, [isNavSticky, isMobile, secondary, visibleHeader])

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleNavigation)
    }

    return () => {
      if (window) {
        window.removeEventListener('scroll', handleNavigation)
      }
    }
  }, [handleNavigation])

  // Trigger for Navbar Slider
  const trigger = useScrollTrigger({ target: global as unknown as Window })

  // Conditioned Icons
  const supportMenuItem = !isMobile && (
    <StyledIconNavItem>
      <StyledNavBSB><span>BSB</span>{BSB}</StyledNavBSB>
    </StyledIconNavItem>
  )

  const profileMenuItem = (
    <StyledIconNavItem>
      <StyledLoginButton startIcon={<Icon icon={faLock} />} primary>Login</StyledLoginButton>
    </StyledIconNavItem>
  )

  return (
    <SearchBarContext.Provider value={{ isOpen, setIsOpen }}>
      <Slide appear={false} direction="down" in={!trigger}>
        <StyledNavigationContainer $secondary={secondary} $sticky={isNavSticky} $visibleHeader={visibleHeader}>
          <CreditUnionLogo width={theme.typography.pxToRem(logoWidth)} height={theme.typography.pxToRem(logoHeight)} notext={isNavSticky} />
          {!isMobile && <StyledMenuContainer {...props} />}
          <StyledRightMenuContainer>
            {supportMenuItem}
            {profileMenuItem}
            <SearchBar />
            {isMobile && (
              <StyledBrokenBarIcon onClick={openMobileMenu}>
                <BrokenBarIcon width={29} height={20} />
              </StyledBrokenBarIcon>
            )}
          </StyledRightMenuContainer>
        </StyledNavigationContainer>
      </Slide>
    </SearchBarContext.Provider>
  )
}
