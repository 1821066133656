
import styled, { css } from 'styled-components'
import { Icon } from '../icon/icon'

export type StyledSearchIconContainerProps = {
  $secondary?: boolean
  $sticky?: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
}

const mobileStyles = css`
  width: ${props => props.theme.typography.pxToRem(40)};
  min-width: ${props => props.theme.typography.pxToRem(40)};
  height: ${props => props.theme.typography.pxToRem(40)};
  min-height: ${props => props.theme.typography.pxToRem(40)};
`

const stickyAndSecondaryStyles = css`
  width: ${props => props.theme.typography.pxToRem(52)};
  min-width: ${props => props.theme.typography.pxToRem(52)};
  height: ${props => props.theme.typography.pxToRem(52)};
  min-height: ${props => props.theme.typography.pxToRem(52)};
`

const primaryStyles = css`
  width: ${props => props.theme.typography.pxToRem(67)};
  min-width: ${props => props.theme.typography.pxToRem(67)};
  height: ${props => props.theme.typography.pxToRem(67)};
  min-height: ${props => props.theme.typography.pxToRem(67)};
`

export const StyledSearchIconContainer = styled.div<StyledSearchIconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${props => props.theme.typography.pxToRem(4)};
  border-radius: 50%;
  background-color: #f2f2f2;
  transition: all 0.2s ease;
  ${mobileStyles}

  &:hover {
    cursor: pointer;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    ${props => (props.$secondary || props.$sticky) ? stickyAndSecondaryStyles : primaryStyles}
  }
`

export const StyledSearchIcon = styled(Icon)<{$focus?: boolean}>`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  transition: color 0.2s ease;
  fill: ${props => props.$focus && props.theme.palette.primary.main};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(20)};
  }
`