
import React from 'react'
import { IconComponent } from './iconComponentType'

export const Triangle: IconComponent = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 90.311 85.02">
      <g fill="none" transform="translate(66.877 85.02) rotate(-158)">
        <path stroke="none" d="M36.065,0,72.13,62.555H0Z" />
        <path fill="#ed8b00" d="M 36.06483459472656 6.006393432617188 L 5.192466735839844 59.55492401123047 L 66.93720245361328 59.55492401123047 L 36.06483459472656 6.006393432617188 M 36.06483459472656 -3.814697265625e-06 L 72.12966918945312 62.55492401123047 L 0 62.55492401123047 L 36.06483459472656 -3.814697265625e-06 Z" />
      </g>
    </svg>
  )
}
