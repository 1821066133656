import React, { ComponentProps } from 'react'
import styled from 'styled-components'

export type CreditUnionLogoProps = ComponentProps<'svg'> & {
  notext?: boolean
}

export const CreditUnionLogo: React.FC<CreditUnionLogoProps> = ({ notext, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  {...props}  viewBox={notext ? '0 0 41.365 54.023' : '0 0 146.228 70'}>
      <defs>
        <linearGradient id="a" x1="0.5" y1="-0.103" x2="0.5" y2="0.556" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#f37021" />
          <stop offset="1" stopColor="#fdb813" />
        </linearGradient>
        <linearGradient id="b" x1="0.5" y1="-0.019" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#b10f1f" />
          <stop offset="0.483" stopColor="#ef4223" />
          <stop offset="1" stopColor="#f37021" />
        </linearGradient>
        <linearGradient id="c" x1="0.5" y1="-0.056" x2="0.5" y2="2.219" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#b10f1f" />
          <stop offset="0.327" stopColor="#ef4223" />
          <stop offset="1" stopColor="#f37021" />
        </linearGradient>
      </defs>
      {!notext ? (
        <g transform="translate(-1292 -312)"><g transform="translate(1292 312)">
          <path fill="#555557" d="M166.416,199.12a3.247,3.247,0,0,0-1.856.5,4.006,4.006,0,0,0-1.221,1.3,5.617,5.617,0,0,0-.667,1.758,9.52,9.52,0,0,0-.2,1.9,9.132,9.132,0,0,0,.2,1.856,5.726,5.726,0,0,0,.635,1.709,3.647,3.647,0,0,0,1.188,1.254,3.264,3.264,0,0,0,1.823.488,3.388,3.388,0,0,0,2.556-.928,4.389,4.389,0,0,0,1.123-2.49h4.46a7.63,7.63,0,0,1-2.6,5.111,8.407,8.407,0,0,1-5.5,1.758,9.229,9.229,0,0,1-3.467-.634,7.69,7.69,0,0,1-2.686-1.775,8.065,8.065,0,0,1-1.725-2.718,9.415,9.415,0,0,1-.619-3.467,11.217,11.217,0,0,1,.57-3.63,8.156,8.156,0,0,1,1.676-2.9,7.674,7.674,0,0,1,2.7-1.9,9.174,9.174,0,0,1,3.646-.684,10.56,10.56,0,0,1,2.881.391,7.692,7.692,0,0,1,2.475,1.188,6.146,6.146,0,0,1,2.556,4.8h-4.526a3.1,3.1,0,0,0-3.418-2.9" transform="translate(-157.853 -184.27)" />
          <path fill="#555557" d="M194.643,196.092v3.126h.065a5.646,5.646,0,0,1,.879-1.449,5.761,5.761,0,0,1,1.27-1.14,6.229,6.229,0,0,1,1.531-.732,5.514,5.514,0,0,1,1.693-.261,3.6,3.6,0,0,1,1.009.164v4.3q-.326-.065-.782-.114a8.248,8.248,0,0,0-.879-.049,4.935,4.935,0,0,0-2.149.423,3.715,3.715,0,0,0-1.416,1.156,4.616,4.616,0,0,0-.765,1.709,9.271,9.271,0,0,0-.228,2.116v7.585h-4.623V196.092Z" transform="translate(-172.342 -184.27)" />
          <path fill="#555557" d="M215.8,208.887a4.738,4.738,0,0,0,5.4.309,2.9,2.9,0,0,0,1.238-1.481h4.069a7.725,7.725,0,0,1-2.995,4.33,8.818,8.818,0,0,1-4.884,1.3,9.628,9.628,0,0,1-3.581-.635,7.437,7.437,0,0,1-2.7-1.807,8.121,8.121,0,0,1-1.709-2.8,10.24,10.24,0,0,1-.6-3.581,9.822,9.822,0,0,1,.619-3.516,8.136,8.136,0,0,1,4.477-4.688,8.71,8.71,0,0,1,3.5-.684,8,8,0,0,1,3.744.831A7.467,7.467,0,0,1,225,198.7a9.159,9.159,0,0,1,1.481,3.191,11.879,11.879,0,0,1,.326,3.744H214.66a4.668,4.668,0,0,0,1.139,3.256m5.226-8.856a3.248,3.248,0,0,0-2.523-.911,4.022,4.022,0,0,0-1.839.375,3.507,3.507,0,0,0-1.173.928,3.405,3.405,0,0,0-.618,1.172,5.269,5.269,0,0,0-.212,1.107h7.521a5.349,5.349,0,0,0-1.156-2.67" transform="translate(-181.193 -184.27)" />
          <path fill="#555557" d="M255.626,205.955a4.758,4.758,0,0,1-2.132,1.969,7.08,7.08,0,0,1-2.979.6,7.2,7.2,0,0,1-3.321-.732,6.673,6.673,0,0,1-2.36-1.987,8.993,8.993,0,0,1-1.4-2.881,12.084,12.084,0,0,1-.472-3.386,11.355,11.355,0,0,1,.472-3.272,8.493,8.493,0,0,1,1.4-2.784,6.8,6.8,0,0,1,5.583-2.67,6.626,6.626,0,0,1,2.849.635,4.965,4.965,0,0,1,2.133,1.872h.065v-8.465h4.623V208.1h-4.4v-2.149Zm-.2-8.318a4.908,4.908,0,0,0-.667-1.693,3.768,3.768,0,0,0-1.221-1.189,3.584,3.584,0,0,0-1.889-.455,3.759,3.759,0,0,0-1.921.455,3.611,3.611,0,0,0-1.253,1.2,5.319,5.319,0,0,0-.684,1.709,9.258,9.258,0,0,0-.212,2,8.566,8.566,0,0,0,.228,1.953,5.315,5.315,0,0,0,.733,1.742,4.042,4.042,0,0,0,1.27,1.238,3.436,3.436,0,0,0,1.839.472,3.665,3.665,0,0,0,1.9-.456,3.464,3.464,0,0,0,1.221-1.221,5.466,5.466,0,0,0,.651-1.741,10.258,10.258,0,0,0,.2-2.019,10.012,10.012,0,0,0-.2-2" transform="translate(-195.918 -179.449)" />
          <path fill="#555557" d="M277.829,184.858h4.623v3.809h-4.623Zm4.623,23.245h-4.623V191.272h4.623Z" transform="translate(-211.513 -179.449)" />
          <path fill="#555557" d="M300.181,192.378v3.093H296.8v8.334a1.565,1.565,0,0,0,1.952,1.953q.391,0,.749-.033a6.085,6.085,0,0,0,.683-.1v3.581a10.828,10.828,0,0,1-1.3.13c-.478.021-.944.033-1.4.033a13.462,13.462,0,0,1-2.034-.148,4.78,4.78,0,0,1-1.694-.57,3,3,0,0,1-1.155-1.2,4.3,4.3,0,0,1-.423-2.051v-9.929h-2.8v-3.093h2.8v-5.046H296.8v5.046Z" transform="translate(-216.676 -180.556)" />
          <path fill="#555557" d="M170.843,260.868V257.84h-.065a5.817,5.817,0,0,1-2.36,2.589,6.732,6.732,0,0,1-3.435.9,7.471,7.471,0,0,1-2.718-.44,4.518,4.518,0,0,1-1.823-1.253,5.069,5.069,0,0,1-1.026-1.986,10.005,10.005,0,0,1-.326-2.669V244.069h2.051v10.939a5.462,5.462,0,0,0,1.058,3.451,5.289,5.289,0,0,0,5.714.6,4.828,4.828,0,0,0,1.563-1.513,6.83,6.83,0,0,0,.911-2.164,10.627,10.627,0,0,0,.294-2.492v-8.822h2.051v16.8Z" transform="translate(-158.406 -205.932)" />
          <path fill="#555557" d="M188.647,243.674v2.9h.065a4.921,4.921,0,0,1,2.084-2.457,6.123,6.123,0,0,1,3.288-.928,8.274,8.274,0,0,1,2.946.457,4.656,4.656,0,0,1,1.9,1.286,4.807,4.807,0,0,1,1.009,2.034,11.473,11.473,0,0,1,.292,2.7v10.809h-2.051V249.99a9.66,9.66,0,0,0-.2-2,4.236,4.236,0,0,0-.683-1.612,3.328,3.328,0,0,0-1.318-1.074,4.844,4.844,0,0,0-2.068-.39,5.229,5.229,0,0,0-2.2.439,4.765,4.765,0,0,0-1.628,1.2,5.567,5.567,0,0,0-1.041,1.823,7.42,7.42,0,0,0-.407,2.295v9.8H186.6v-16.8Z" transform="translate(-170.708 -205.536)" />
          <path fill="#555557" d="M216.564,235.7h-2.051v-3.288h2.051Zm0,19.957h-2.051v-16.8h2.051Z" transform="translate(-183.194 -200.716)" />
          <path fill="#555557" d="M232.221,243.9a7.428,7.428,0,0,1,2.491,1.921,8.325,8.325,0,0,1,1.53,2.817,11.575,11.575,0,0,1,0,6.869,8.322,8.322,0,0,1-1.53,2.816,7.24,7.24,0,0,1-2.491,1.9,8.614,8.614,0,0,1-6.8,0,7.235,7.235,0,0,1-2.491-1.9,8.3,8.3,0,0,1-1.53-2.816,11.588,11.588,0,0,1,0-6.869,8.306,8.306,0,0,1,1.53-2.817,7.422,7.422,0,0,1,2.491-1.921,8.437,8.437,0,0,1,6.8,0m-5.974,1.628a5.646,5.646,0,0,0-1.84,1.628,7.406,7.406,0,0,0-1.107,2.295,9.356,9.356,0,0,0,0,5.243,7.376,7.376,0,0,0,1.107,2.295,5.652,5.652,0,0,0,1.84,1.628,5.656,5.656,0,0,0,5.144,0,5.663,5.663,0,0,0,1.84-1.628,7.389,7.389,0,0,0,1.107-2.295,9.369,9.369,0,0,0,0-5.243,7.419,7.419,0,0,0-1.107-2.295,5.657,5.657,0,0,0-1.84-1.628,5.661,5.661,0,0,0-5.144,0" transform="translate(-186.04 -205.536)" />
          <path fill="#555557" d="M254.5,243.674v2.9h.065a4.921,4.921,0,0,1,2.084-2.457,6.121,6.121,0,0,1,3.288-.928,8.276,8.276,0,0,1,2.946.457,4.655,4.655,0,0,1,1.9,1.286,4.807,4.807,0,0,1,1.009,2.034,11.473,11.473,0,0,1,.292,2.7v10.809h-2.051V249.99a9.7,9.7,0,0,0-.2-2,4.235,4.235,0,0,0-.683-1.612,3.328,3.328,0,0,0-1.318-1.074,4.843,4.843,0,0,0-2.068-.39,5.229,5.229,0,0,0-2.2.439,4.764,4.764,0,0,0-1.628,1.2,5.567,5.567,0,0,0-1.041,1.823,7.42,7.42,0,0,0-.407,2.295v9.8h-2.051v-16.8Z" transform="translate(-200.16 -205.536)" />
          <path fill="#555557" d="M284.653,256.29a2.713,2.713,0,0,0,.8.879,3.431,3.431,0,0,0,1.123.5,5.276,5.276,0,0,0,1.319.163,4.93,4.93,0,0,0,1.026-.114,3.474,3.474,0,0,0,.976-.358,2.221,2.221,0,0,0,.733-.651,1.715,1.715,0,0,0,.293-1.027q0-1.041-1.384-1.562a27.479,27.479,0,0,0-3.858-1.042q-1.01-.228-1.969-.537a6.661,6.661,0,0,1-1.709-.814,3.951,3.951,0,0,1-1.206-1.269,3.609,3.609,0,0,1-.455-1.873,5.067,5.067,0,0,1,.635-2.67,4.683,4.683,0,0,1,1.676-1.644A7.363,7.363,0,0,1,285,243.43a14.48,14.48,0,0,1,2.67-.244,13.307,13.307,0,0,1,2.653.261,7.015,7.015,0,0,1,2.295.879,5.282,5.282,0,0,1,1.677,1.644,5.536,5.536,0,0,1,.8,2.589h-4.4a2.024,2.024,0,0,0-1.009-1.807,4.617,4.617,0,0,0-2.149-.472,7.8,7.8,0,0,0-.846.049,2.8,2.8,0,0,0-.831.212,1.7,1.7,0,0,0-.635.472,1.243,1.243,0,0,0-.26.829,1.268,1.268,0,0,0,.455,1.01,3.5,3.5,0,0,0,1.189.635,14.948,14.948,0,0,0,1.676.44q.944.195,1.921.423a19.659,19.659,0,0,1,1.971.553,6.553,6.553,0,0,1,1.709.862,4.194,4.194,0,0,1,1.2,1.335,3.923,3.923,0,0,1,.456,1.971,5.109,5.109,0,0,1-2.409,4.59,7.151,7.151,0,0,1-2.458.961,14.366,14.366,0,0,1-2.816.276A13.965,13.965,0,0,1,285,260.6a7.541,7.541,0,0,1-2.491-.977,5.576,5.576,0,0,1-1.791-1.806,5.651,5.651,0,0,1-.765-2.816h4.395a2.533,2.533,0,0,0,.31,1.286" transform="translate(-212.461 -205.537)" />
          <path fill="#555557" d="M311.321,246.116a5.544,5.544,0,0,1,1.824-1.726,7.93,7.93,0,0,1,2.49-.928,14.145,14.145,0,0,1,2.783-.277,18.874,18.874,0,0,1,2.572.18,7.869,7.869,0,0,1,2.376.7,4.693,4.693,0,0,1,1.758,1.448,4.048,4.048,0,0,1,.684,2.459v8.757a17.492,17.492,0,0,0,.13,2.182,3.922,3.922,0,0,0,.456,1.562h-4.688a6.5,6.5,0,0,1-.212-.8,6.95,6.95,0,0,1-.115-.83,6.018,6.018,0,0,1-2.6,1.6,10.491,10.491,0,0,1-3.06.455,8.287,8.287,0,0,1-2.247-.293,5.15,5.15,0,0,1-1.823-.911,4.256,4.256,0,0,1-1.221-1.563,5.282,5.282,0,0,1-.439-2.247,4.892,4.892,0,0,1,.505-2.36,4.221,4.221,0,0,1,1.3-1.482,5.878,5.878,0,0,1,1.823-.829,19.5,19.5,0,0,1,2.068-.44q1.041-.163,2.051-.261a12.09,12.09,0,0,0,1.79-.293,3.1,3.1,0,0,0,1.237-.569,1.252,1.252,0,0,0,.423-1.09,2.472,2.472,0,0,0-.243-1.189,1.8,1.8,0,0,0-.652-.684,2.558,2.558,0,0,0-.944-.325,7.758,7.758,0,0,0-1.156-.081,3.507,3.507,0,0,0-2.149.585,2.617,2.617,0,0,0-.913,1.954h-4.622a5.383,5.383,0,0,1,.814-2.7m9.133,6.527a7.591,7.591,0,0,1-.945.244q-.506.1-1.058.163c-.369.043-.738.1-1.107.163a8.417,8.417,0,0,0-1.025.26,3.039,3.039,0,0,0-.879.44,2.074,2.074,0,0,0-.6.7,2.257,2.257,0,0,0-.227,1.074,2.181,2.181,0,0,0,.227,1.042,1.749,1.749,0,0,0,.619.667,2.593,2.593,0,0,0,.911.342,5.854,5.854,0,0,0,1.074.1,4.085,4.085,0,0,0,2.116-.455,3.061,3.061,0,0,0,1.108-1.091A3.49,3.49,0,0,0,321.1,255a8.744,8.744,0,0,0,.082-1.042v-1.726a2.061,2.061,0,0,1-.732.407" transform="translate(-225.896 -205.537)" />
          <g transform="translate(92.629 0)">
            <path fill="url(#a)" d="M378.823,176.287h-53.39l26.765,51Z" transform="translate(-325.433 -176.287)" />
            <g transform="translate(0 0)">
              <path fill="none" d="M66.716,176.287h0Z" transform="translate(-66.716 -176.287)" />
              <path fill="url(#b)" d="M123.348,218.619l22.3,27.672-.2-70Z" transform="translate(-92.045 -176.291)" />
              <path fill="url(#d)" d="M83.625,208.507,66.716,176.287h0l.112,28.586Z" transform="translate(-66.716 -176.287)" />
              <path fill="url(#c)" d="M83.625,208.507,66.716,176.287h0l.112,28.586Z" transform="translate(-66.716 -176.287)" />
            </g>
          </g>
        </g></g>) : (
        <g transform="translate(-1384.629 -312)"><g transform="translate(1384.629 312)">
          <g transform="translate(0 0)">
            <path fill="url(#a)" d="M366.637,176.287h-41.2l20.656,39.362Z" transform="translate(-325.433 -176.287)" />
            <g transform="translate(0 0)">
              <path fill="none" d="M66.716,176.287h0Z" transform="translate(-66.716 -176.287)" />
              <path fill="url(#b)" d="M123.348,208.959l17.207,21.356L140.4,176.3Z" transform="translate(-99.19 -176.292)" />
              <path fill="url(#c)" d="M79.766,201.153l-13.05-24.866h0l.086,22.061Z" transform="translate(-66.716 -176.287)" />
            </g>
          </g>
        </g></g>
      )
      }
    </svg>
  )
}

export const StyledCreditUnionLogo = styled(CreditUnionLogo)`
  transition: all 0.2s ease;
`
