import { Button as BaseButton, ButtonProps as ButtonBaseProps } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { Icon, IconProps } from '../icon/icon'
import  {
  hoverStylesBlack,
  hoverStylesGreen,
  hoverStylesOrange,
  hoverStylesCta,
  hoverStylesDark,
} from './buttonHover'

export type ButtonTypeProps = 
  // Default
  | { primary: true; secondary?: never; buttonColor: string;  text?: never; dark?: never; cta?: never; icon?: never }
  // Primary 
  | { primary?: false; secondary: true; buttonColor: 'black' | 'orange';  text?: never; dark?: never; cta?: never; icon?: never }
  // Secondary
  | { primary?: false; secondary?: false; buttonColor?: never;  text: true; dark?: never; cta?: never; icon?: IconProps['icon']}
  // Text Button
  | { primary?: false; secondary?: false; buttonColor?: never;  text?: never; dark: true; cta?: never; icon?: never }
  // Dark Background Button
  | { primary?: false; secondary?: false; buttonColor?: never;  text?: never; dark?: never; cta: true; icon?: never  }

export type ButtonProps = ButtonBaseProps & ButtonTypeProps

export const StyledButton = styled(BaseButton)`
  ${props => props.variant === 'outlined' && props.color === 'default' && hoverStylesBlack}
  ${props => props.variant === 'outlined' && props.color === 'primary' && hoverStylesOrange}
  ${props => props.variant === 'outlined' && props.color === 'secondary' && hoverStylesGreen}
  ${props => props.variant === 'contained' && props.color === 'primary' && hoverStylesCta}
  ${props => props.variant === 'contained' && props.color === 'secondary' && hoverStylesDark}
`
export const ButtonIcon = styled(Icon)`
  height: ${props => props.theme.typography.pxToRem(16)};
  width: ${props => props.theme.typography.pxToRem(16)};
  fill: ${props => props.theme.palette.primary.main};
  background: none;

  &:hover {
    fill: ${props => props.theme.palette.secondary.dark};
  }
`

export const Button: React.FC<ButtonProps> = ({ primary, secondary, buttonColor, text, dark, cta, icon,  ...buttonProps }) => {
  buttonProps.variant = cta ? 'contained' : (text ? 'text' : 'outlined')

  if (primary) {
    switch (buttonColor) {
    case 'black':
      buttonProps.color= 'default'
      break
    case 'orange':
      buttonProps.color = 'primary'
      break
    case 'green':
      buttonProps.color = 'secondary'
      break
    }
  }

  if (secondary) {
    buttonProps.size = 'small'

    switch (buttonColor) {
    case 'black':
      buttonProps.color= 'default'
      break
    case 'orange':
      buttonProps.color = 'primary'
      break
    case 'green':
      buttonProps.color = 'secondary'
      break
    }
  }

  if (cta) {
    buttonProps.color= 'primary'
  }

  if (dark) {
    buttonProps.variant = 'contained'
    buttonProps.color= 'secondary'
  }

  if(text) {
    buttonProps.color = 'primary'
    
    if (icon) {
      buttonProps.endIcon = <ButtonIcon icon={icon}></ButtonIcon>
    }
  }

  return (
    <StyledButton { ...buttonProps } />
  )
}