import React, { ComponentProps } from 'react'
import styled from 'styled-components'

export type NavMenuProps = ComponentProps<typeof StyledNavMenu> & {
  //
}

export const StyledNavMenu = styled.div`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`

export const NavMenu: React.FC<NavMenuProps> = (props) => {
  return (
    <StyledNavMenu {...props} />
  )
}
