import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useContext } from 'react'
import styled from 'styled-components'
import { Button } from '../button/button'
import { Icon } from '../icon/icon'
import { breakpointContext } from './footer'
import Image from 'next/image'

export type ButtonTitleProps = {
  //
}

export type ButtonSubtitleProps = {
  //

}

export type ButtonWrapperProps = {
  // 
}

export type ButtonPanelProps = {
  // 
  icon?: string
}

export type ButtonProps = 
    | (
      {
        button?: never
        subtitle?: never
        title?: never
      } & ButtonWrapperProps
    )
    | (
      {
        button?: never
        subtitle: true
        title?: never
      } & ButtonSubtitleProps
    )
    | (
      {
        button?: never
        subtitle?: never
        title: true
      } & ButtonTitleProps
    )
    | (
      {
        button: true
        subtitle?: never
        title?: never
      } & ButtonPanelProps
    )


export const StyledButton = styled(Button)`
  background-color: rgba(255, 255, 255, 0.06);
  position: relative;
  border-radius: 4px;
  text-decoration: none;
  width: ${props => props.theme.typography.pxToRem(320)};
  margin-bottom: ${props => props.theme.typography.pxToRem(23)};
  margin-left: ${props => props.theme.typography.pxToRem(20)};
  margin-right: ${props => props.theme.typography.pxToRem(20)};
  padding: ${props => props.theme.typography.pxToRem(10)};
  border: unset;

  ${props => props.theme.breakpoints.up('lg')} {
    margin-left: auto;
    margin-right: auto;
  }

  & > span > svg {
    right: ${props => props.theme.typography.pxToRem(10)};
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  &:hover {
    border: unset;
    background-color: rgba(255, 255, 255, 0.06);

    & > span > svg {
      color: ${props => props.theme.palette.secondary.main};
      right: ${props => props.theme.typography.pxToRem(5)};
    }

    &::after {
      content: none;
    }

    & > span {
      -webkit-transform: unset !important;
      -ms-transform: unset !important;
      transform: unset !important;
    }
  }
`

export const ContactButton = styled.div`
  text-decoration: none;
  width: 100%;
`

export const Heading = styled(Typography)`
  color: ${props => props.theme.palette.common.white};
  font-size: ${props => props.theme.typography.pxToRem(22)};
  text-decoration: none;
`

export const MutedText = styled(Typography)`
  color: ${props => props.theme.palette.background.default};
  font-size: ${props => props.theme.typography.pxToRem(15)};
  opacity: 75%;
`

export const Chevron = styled(Icon)`
  color: ${props => props.theme.palette.background.default};
  font-size: ${props => props.theme.typography.pxToRem(13)};
  background: none;
  position: absolute;
`
export const ButtonIcon = styled(Icon)`
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => props.theme.typography.pxToRem(32)};
  background: none;
  margin-left: ${props => props.theme.typography.pxToRem(10)};
  margin-right: ${props => props.theme.typography.pxToRem(10)};
`

const ButtonRow = styled(Grid)`
  ${props => props.theme.breakpoints.down('sm')} {
    justify-content: center;
  }
`


export const FooterButton: React.FC<ButtonProps> = ({ button, title, subtitle, children, ...props }) => {
  if (button) {
    return (
      <ButtonWrapper {...props}>{children}</ButtonWrapper>
    )
  }

  if (title) {
    return (
      <ButtonTitle {...props}>{children}</ButtonTitle>
    )
  }

  if (subtitle) {
    return (
      <ButtonSubTitle {...props}>{children}</ButtonSubTitle>
    )
  }

  return (
    <ButtonRowWrapper {...props}>{children}</ButtonRowWrapper>
  )
}

const ButtonRowWrapper: React.FC<ButtonWrapperProps> = ({ children, ...props }) => {
  const { mobile, tablet } = useContext(breakpointContext)

  return (
    <ButtonRow 
      container
      direction="row" 
      justifyContent={!mobile || !tablet ? 'space-between' : 'center'}
      alignItems="center"
      {...props}
    > 
      {children}
    </ButtonRow>
  )
}

const ButtonWrapper: React.FC<ButtonPanelProps> = ({ icon, children, ...props }) => {
  return (
    <StyledButton dark {...props}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid
          item
        >
          <Image src={icon} height={55} width={55} alt=""/>      
        </Grid>
        <Grid
          item
        >
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
      <Chevron icon={faChevronRight}/>
    </StyledButton>
  )
}

const ButtonTitle: React.FC<ButtonTitleProps> = ({ children, ...props }) => {
  return (
    <Heading {...props}>{children}</Heading>
  )
}

const ButtonSubTitle: React.FC<ButtonSubtitleProps> = ({ children, ...props }) => {
  return (
    <MutedText {...props}>{children}</MutedText>
  )
}