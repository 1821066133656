import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { Icon } from '../icon/icon'

export const StyledCardContainer = styled(Grid)`
  margin-right: ${props => props.theme.typography.pxToRem(84)};
`

export const StyledTitleText = styled.a`
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: 500;
  text-decoration: none;
  transition: margin-right 0.2s ease, color 0.2s ease;
`

export const StyledTitleCheveron = styled(Icon)<{$rotate?: boolean}>`
  position: absolute;
  top: ${props => props.theme.typography.pxToRem(6)};
  right: -${props => props.theme.typography.pxToRem(16)};
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => props.theme.typography.pxToRem(16)};
  transition: right 0.2s ease;
`

export const StyledTitleTextContainer = styled.div`
  position: relative;
  width: ${props => props.theme.typography.pxToRem(90)};
  overflow-wrap: break-word;
  cursor: pointer;

  &:hover {
    ${StyledTitleCheveron} {
      right: -${props => props.theme.typography.pxToRem(26)};
    }

    ${StyledTitleText} {
      color: ${props => props.theme.palette.secondary.dark};
    }
  }
`

export const StyledCardIconContainer = styled(Grid)`
  margin-bottom: ${props => props.theme.typography.pxToRem(30)};
  max-width: ${props => props.theme.typography.pxToRem(50)};
`

export const StyledOverviewTitle = styled.a`
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(26)};
  font-weight: 500;
  transition: color 0.3s ease;
`

export const StyledOverviewCheveron = styled(Icon)`
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(35)};
  transform: translate(${props => props.theme.typography.pxToRem(8)}, ${props => props.theme.typography.pxToRem(10)});
  transition: color 0.3s ease;
`

export const StyledOverviewContainer = styled.div`
  margin-top: ${props => props.theme.typography.pxToRem(22)};
  display: inline-block;

  &:hover {
    ${StyledOverviewCheveron} {
      color: ${props => props.theme.palette.primary.main};
    }

    ${StyledOverviewTitle} {
      color: ${props => props.theme.palette.secondary.dark};
    }
  }
`


