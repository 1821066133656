import { ApolloClient, ApolloClientOptions, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import getConfig from 'next/config'
import deepmerge from 'deepmerge'

const { serverRuntimeConfig } = getConfig()

export const uri = process.env.GRAPHQL_URL || 'http://localhost:8000/graphql'

const cache = new InMemoryCache({
  possibleTypes: serverRuntimeConfig.possibleTypes,
})

export type GetClientOptions = Omit<ApolloClientOptions<NormalizedCacheObject>, 'cache'>

const defaultOptions: GetClientOptions = process.env.NODE_ENV === 'production' ? {} : {
  headers: {
    'x-default-site-url': 'http://nginx:80',
  },
}

export const getClient = (options: GetClientOptions = {}) => {
  return new ApolloClient({
    cache,
    ...deepmerge({ uri, ...defaultOptions }, options),
  })
}

export const client = getClient()