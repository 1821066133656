
import React from 'react'
import { IconComponent } from './iconComponentType'

export const DoubleTriangle:IconComponent = (props) => {  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 142.405 179.779">
      <defs>
        <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#ffb81c"/>
          <stop offset="1" stopColor="#f35a21"/>
        </linearGradient>
      </defs>
      <g transform="translate(-1441.956 -325.221)" >
        <path fill="url(#a)" d="M14146-3475.438l-42.258-123.065h85.9Z" transform="translate(-12605.281 3980.438)"/>
        <path fill="#e6e6e6" opacity="0.647" mix-blend-mode="multiply" d="M14138.872-3486.012l-75.5-139.561h122.406Z" transform="translate(-12621.414 3950.793)"/>
      </g>
    </svg>
  )
}