import styled from 'styled-components'
import { Icon } from '../icon/icon'
import { Button } from '../button/button'
import { Typography } from '@material-ui/core'

export const StyledNavIcon = styled(Icon)<{$isProfile?: boolean}>`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  margin-right: ${props => props.$isProfile ? 0 : props.theme.typography.pxToRem(24)};
  color: ${props => props.$isProfile && props.theme.palette.secondary.dark};
  transition: color 0.2s ease;

  &:hover {
    cursor: pointer;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    margin-right: ${props => props.theme.typography.pxToRem(12)};
    padding: none;
  }
`

export const StyledBrokenBarIcon = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin-left: ${props => props.theme.typography.pxToRem(24)};
`

export const StyledIconNavItem = styled.div<{ $focus?: boolean }>`
  display: grid;
  justify-items: center;
  list-style-type: none;
  padding-right: ${props => props.theme.typography.pxToRem(16)};
  cursor: pointer;

  ${props => props.theme.breakpoints.up('sm')} {
    display: inline-block;
    font-size: ${props => props.theme.typography.pxToRem(18)};
    padding-left: ${props => props.theme.typography.pxToRem(30)};
    padding-right: ${props => props.theme.typography.pxToRem(30)};
  }

  &:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }
`

export const StyledLoginButton = styled(Button)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  padding: 4px 7px 4px 7px;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(20)};
    padding: 8px 15px 8px 15px;
  }
`

export const StyledNavBSB = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: 500;

  & > span {
    font-weight: 300;
    padding-right: ${props => props.theme.typography.pxToRem(5)};
    color: ${props => props.theme.palette.secondary.dark};
  }
`