
import React, { useRef, useState, useContext, ChangeEventHandler } from 'react'
import { DesktopSearchBar } from './DesktopSearchBar'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import { MobileSearchBarContainer } from './MobileSearchBarContainer'
import { SearchBarContext } from '../PrimaryNavigation/PrimaryNavigation'

export type SearchBarProps = React.ComponentProps<typeof Grid> & {
  onToggle?: () => void
  onClear?: () => void
  onChange?: ChangeEventHandler<HTMLInputElement>
  focus?: boolean
  inputRef?: React.MutableRefObject<HTMLInputElement>
  sticky?: boolean
  secondary?: boolean
}

export const SearchBar: React.FC<SearchBarProps> = ( props ) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
  const [focus, setFocus] = useState(false)
  const inputRef = useRef(null)
  const { isOpen, setIsOpen } = useContext(SearchBarContext)
  const clearInput = () => {
    inputRef.current.value = ''
  }

  const onToggleDesktop = () => {
    setFocus(!focus)
    isOpen ? setIsOpen(false) : setIsOpen(true)
  }

  const onToggleMobile = () => {
    setFocus(!focus)
  }

  return(
    <React.Fragment>
      {isTablet ?
        <DesktopSearchBar inputRef={inputRef} onClear={clearInput} focus={focus} onToggle={onToggleDesktop} {...props} />

        :
        <MobileSearchBarContainer inputRef={inputRef} onClear={clearInput} focus={focus} onToggle={onToggleMobile} {...props} />
      }
    </React.Fragment>
  )
}