import React, { useCallback, useState } from 'react'
import { HeaderGlobals } from '../../../globals/functions/header'
import { PrimaryNavigation } from '../../PrimaryNavigation/PrimaryNavigation'
import { NavMenu } from '../../PrimaryNavigation/NavMenu'
import { NavItem } from '../../PrimaryNavigation/NavItem'
import { NavDropdownLinks } from '../../NavigationDropdown/NavDropdownLinks'
import { NavDropdownUtilityBar } from '../../NavigationDropdown/NavDropdownUtilityBar'
import { NavDropdownPanel } from '../../NavigationDropdown/NavDropdownPanel'
import { CreditUnionIcon, IconCodeType } from '../../icon/CreditUnionIcon'
import { MobileMenu } from '../../MobileMenu/MobileMenu'
import { StyledMobileMenuUtility } from '../../MobileMenu/styledMobileMenuUtility'
import {
  MobileMenuBackItem,
  MobileMenuHeader,
  MobileMenuLinkItem,
  MobileMenuUtilityItem,
} from '../../MobileMenu/MobileMenuItem'

export type HeaderProps = HeaderGlobals

const ICON_GROUP_TYPE = 'modules\\megaMenu\\nodes\\IconGroup' as const

export const HeaderFragment: React.VFC<HeaderProps> = ({ navigation }) => {
  const [open, setOpen] = useState(false)
  const toggleMenu = useCallback(() => setOpen(!open), [setOpen, open])
  const closeMenu = useCallback(() => setOpen(false), [setOpen])
  let idx = 0

  return (
    <>
      <PrimaryNavigation BSB={'805 007'} secondary={false} openMobileMenu={toggleMenu}>
        <NavMenu>
          {navigation.nodes.map((node, i) => {
            const menus = node.children.filter((node) => node.type !== ICON_GROUP_TYPE)
            const iconGroup = node.children.find((node) => node.type === ICON_GROUP_TYPE)

            return (
              <NavItem
                key={i}
                title={node.title}
                aria-haspopup={node.children ? 'true' : 'false'}
              >
                <NavDropdownPanel title={node.title}>
                  <NavDropdownLinks>
                    {menus.map((node, i) => (
                      <NavDropdownLinks card key={i} title={node.title} href={node.url || '#'}>
                        {node.children?.map((node, i) => (
                          <NavDropdownLinks item key={i} href={node.url}>
                            {node.title}
                          </NavDropdownLinks>
                        ))}
                      </NavDropdownLinks>
                    ))}
                  </NavDropdownLinks>
                  {iconGroup && (
                    <NavDropdownUtilityBar title={iconGroup.title} href={iconGroup.url || '#'}>
                      {iconGroup.children?.map((node, i) => (
                        <NavDropdownUtilityBar card key={i} title={node.title} iconCode={node.data.icon as IconCodeType} href={node.url || '#'} />
                      ))}
                    </NavDropdownUtilityBar>
                  )}
                </NavDropdownPanel>
              </NavItem>
            )
          })}
        </NavMenu>
      </PrimaryNavigation>
      <MobileMenu wrapper portal={'#mobile-menu'} isMenuOpen={open}>
        <MobileMenu navigation level={0} idx={idx++}>
          <MobileMenuHeader phoneNumber={'8202 7777'} bsbNumber={'805 007'} onClose={closeMenu} />
          {navigation.nodes.map((node, i) => {
            const menus = node.children.filter((node) => node.type !== ICON_GROUP_TYPE)
            const iconGroup = node.children.find((node) => node.type === ICON_GROUP_TYPE)

            return (
              <MobileMenu item key={i} name={node.title} level={0} idx={idx++}>
                <MobileMenu navigation level={1} idx={idx}>
                  <MobileMenuBackItem level={1} idx={idx}>Back {node.title}</MobileMenuBackItem>
                  <MobileMenuLinkItem href={node.url || '#'} isTitle>{node.title}</MobileMenuLinkItem>
                  {menus.length > 0 && menus.map((node, i) => (
                    <MobileMenu key={i} item name={node.title} level={1} idx={idx++}>
                      <MobileMenu navigation level={2} idx={idx}>
                        <MobileMenuBackItem level={2} idx={idx}>Back</MobileMenuBackItem>
                        <MobileMenuLinkItem href={node.url || '#'} isTitle>{node.title}</MobileMenuLinkItem>
                        {node.children.map((node, i) => (
                          <MobileMenuLinkItem key={i} href={node.url || '#'}>
                            {node.title}
                          </MobileMenuLinkItem>
                        ))}
                      </MobileMenu>
                    </MobileMenu>
                  ))}
                  {iconGroup && (
                    <StyledMobileMenuUtility>
                      {iconGroup.children.map((node, i) => (
                        <MobileMenuUtilityItem key={i} href={node.url || '#'} icon={<CreditUnionIcon iconCode={node.data.icon as IconCodeType} />}>
                          {node.title}
                        </MobileMenuUtilityItem>
                      ))}
                    </StyledMobileMenuUtility>
                  )}
                </MobileMenu>
              </MobileMenu>
            )
          })}
        </MobileMenu>
      </MobileMenu>
    </>
  )
}