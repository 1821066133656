import React from 'react'
import { Grid } from '@material-ui/core'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { IconCodeType } from '../icon/CreditUnionIcon'
import { CreditUnionIcon } from '../icon/CreditUnionIcon'
import { StyledCardContainer, StyledCardIconContainer } from './styledNavDropdownUtilityBar'
import { StyledTitleTextContainer, StyledTitleText, StyledTitleCheveron } from './styledNavDropdownUtilityBar'
import Link, { LinkProps } from 'next/link'

export type UtilityBarCardProps = React.ComponentProps<typeof StyledCardContainer> & Pick<LinkProps, 'href'> & {
  title: string
  iconCode: IconCodeType
}

export const UtilityBarCard: React.FC<UtilityBarCardProps> = ({ title, iconCode, href, children, item = true, ...props }) => {
  return (
    <StyledCardContainer item={item} {...props}>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Grid container direction="column">
            <StyledCardIconContainer item>
              <CreditUnionIcon height={47} iconCode={iconCode} />
            </StyledCardIconContainer>
            <StyledTitleTextContainer>
              <StyledTitleCheveron icon={faChevronRight} />
              <Link href={href} passHref>
                <StyledTitleText>
                  {title}
                </StyledTitleText>
              </Link>
            </StyledTitleTextContainer>
          </Grid>
        </Grid>
      </Grid>
    </StyledCardContainer>
  )
}