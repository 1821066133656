import React from 'react'

import { IconComponent } from './CreditUnionIcon/iconComponentType'
import { AnalyticsIcon } from './CreditUnionIcon/AnalyticsIcon'
import { BrokenBarIcon } from './CreditUnionIcon/BrokenBarIcon'
import { CalculatorIcon } from './CreditUnionIcon/CalculatorIcon'
import { MoneyIcon } from './CreditUnionIcon/MoneyIcon'
import { PiggyIcon } from './CreditUnionIcon/PiggyIcon'
import { UmbrellaIcon } from './CreditUnionIcon/UmbrellaIcon'
import { WalletIcon } from './CreditUnionIcon/WalletIcon'
import { ChatToUsIcon } from './CreditUnionIcon/ChatToUsIcon'
import { ContactUsIcon } from './CreditUnionIcon/ContactUsIcon'
import { FindUsIcon } from './CreditUnionIcon/FindUsIcon'
import { MobileLendingIcon } from './CreditUnionIcon/MobileLendingIcon'
import { TimesIcon } from './CreditUnionIcon/TimesIcon'
import { FreeChargeIcon } from './CreditUnionIcon/FreeChargeIcon'
import { VisaDebitCardIcon } from './CreditUnionIcon/VisaDebitCardIcon'
import { ClockIcon } from './CreditUnionIcon/ClockIcon'
import { DoubleTriangle } from './CreditUnionIcon/DoubleTriangle'
import { Triangle } from './CreditUnionIcon/Triangle'
//Mapping of iconCode to Icon Function Component
type CreditUnionIconMapType = Record<string, IconComponent>

export const CreditUnionIconMap: CreditUnionIconMapType = {
  'analytics': AnalyticsIcon,
  'hamburger': BrokenBarIcon,
  'calculator': CalculatorIcon,
  'money': MoneyIcon,
  'piggy': PiggyIcon,
  'umbrella': UmbrellaIcon,
  'wallet': WalletIcon,
  'contact': ContactUsIcon,
  'chat': ChatToUsIcon,
  'findus': FindUsIcon,
  'mobilelending': MobileLendingIcon,
  'times': TimesIcon,
  'freecharge': FreeChargeIcon,
  'visacard': VisaDebitCardIcon,
  'clock': ClockIcon,
  'double-traingle': DoubleTriangle,
  'traingle': Triangle,
} as const

export type IconCodeType = keyof typeof CreditUnionIconMap

export type CreditUnionIconProps = React.ComponentProps<IconComponent> & {
  iconCode: IconCodeType
}

export const CreditUnionIcon: React.FC<CreditUnionIconProps> = ({ iconCode , ...props }) => {
  const Icon = CreditUnionIconMap[iconCode]

  return <Icon { ...props } />
}

export {
  AnalyticsIcon,
  BrokenBarIcon,
  CalculatorIcon,
  MoneyIcon,
  PiggyIcon,
  UmbrellaIcon,
  WalletIcon,
  ContactUsIcon,
  ChatToUsIcon,
  FindUsIcon,
  MobileLendingIcon,
  TimesIcon,
  FreeChargeIcon,
  VisaDebitCardIcon,
  ClockIcon,
  DoubleTriangle,
  Triangle,
}
